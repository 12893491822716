import React from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"
import {selectCalculator} from "../store/store.selectors"
import {storeActions} from "../store/store.actions"

const StyledForm = styled.form`
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledInput = styled.input`
    box-sizing: border-box;
    padding: 10px 15px;
    flex-grow: 1;
    height: 49px;
    width: 180px;
    background-color: #ffc;
    margin: 6px 0;
`

export function Calculator(): JSX.Element {
    const {auc, roman_numerals, ad} = useSelector(selectCalculator)
    const dispatch = useDispatch()

    const onAUCChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const {value} = evt.target

        dispatch(storeActions.setCalculatorValue("auc", Number(value)))
    }

    const onRomanNumeralsChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const {value} = evt.target

        dispatch(storeActions.setCalculatorValue("roman_numerals", value))
    }

    const onADChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const {value} = evt.target

        dispatch(storeActions.setCalculatorValue("ad", Number(value)))
    }

    return (
        <StyledForm>
            <h2>Calculator</h2>
            <p>Convert a year between Roman and Christian Epochs</p>
            <label>Roman year</label>
            <StyledInput
                name="auc"
                type="number"
                placeholder="AUC Year"
                value={auc}
                onChange={onAUCChange}
            />
            <label>Roman Numerals</label>
            <StyledInput
                name="roman_numerals"
                type="text"
                placeholder="AD Year"
                value={roman_numerals}
                onChange={onRomanNumeralsChange}
            />
            <label>Christian year</label>
            <StyledInput
                name="ad"
                type="number"
                placeholder="AD Year"
                value={ad}
                onChange={onADChange}
            />
        </StyledForm>
    )
}
