import {IAppStore, AUC_DEHYDRATED_STORE} from "../types/store/IAppStore"
import {StoreAction} from "./store.actions"
import {IYearFormats} from "../types/YearFormats"
import { fromAD, fromAUC, fromRoman } from "../lib/Calculations"

export const storeReducer = (
    state: IAppStore = AUC_DEHYDRATED_STORE,
    action: StoreAction
): IAppStore => {
    if (!state) {
        return state
    }

    switch (action.type) {
        case "@store/SET_CALCULATOR_VALUE":
            let calculator: IYearFormats

            if (action.key === "auc") {
                const auc = Number(action.value)

                calculator = fromAUC(auc)
            } else if (action.key === "ad") {
                const ad = Number(action.value)

                calculator = fromAD(ad)
            } else {
                const numerals = String(action.value)

                calculator = fromRoman(numerals)
            }

            return {
                ...state,
                calculator: calculator,
            }
        default:
            return state
    }
}
