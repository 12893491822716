import {Value} from "../types/Value"
import {IYearFormats} from "../types/YearFormats"

export const storeActions = {
    setCalculatorValue: <T extends keyof IYearFormats>(key: T, value: IYearFormats[T]) =>
        ({
            type: "@store/SET_CALCULATOR_VALUE",
            key,
            value,
        } as const),
}

export type StoreAction = ReturnType<Value<typeof storeActions>>
