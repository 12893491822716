import {applyMiddleware, compose, createStore} from "redux"
import thunk, {ThunkAction} from "redux-thunk"
import {Action, Store} from "redux"
import {IAppStore, AUC_DEHYDRATED_STORE} from "../types/store/IAppStore"
import {storeReducer} from "./store.reducers"
import {StoreAction} from "./store.actions"

export type ReduxNonThunkAction = StoreAction

export type ReduxThunk<R, A> = ThunkAction<R, IAppStore, Action<A>, ReduxNonThunkAction>

export type ReduxAction<R, A> = ReduxNonThunkAction | ReduxThunk<R, A>

export type ReduxStore = Store<IAppStore, ReduxNonThunkAction>

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
    storeReducer,
    AUC_DEHYDRATED_STORE,
    composeEnhancers(applyMiddleware(thunk))
)
