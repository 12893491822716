import { fromAD } from "../../lib/Calculations"
import {IYearFormats} from "../YearFormats"

export interface IAppStore {
    current: IYearFormats
    calculator: IYearFormats
}

const currentAD = new Date().getFullYear()


export const AUC_DEHYDRATED_STORE: IAppStore = {
    calculator: fromAD(currentAD),
    current: fromAD(currentAD),
}

document.title = `Year ${AUC_DEHYDRATED_STORE.current.auc}`
