import { toArabic, toRoman } from "roman-numerals"
import { IYearFormats } from "../types/YearFormats"

export const AD_0_IN_AUC = 753

export const toAUC = (ad: number): number => {
    return ad + AD_0_IN_AUC
}
export const toAD = (auc: number): number => {
    return auc - AD_0_IN_AUC
}

export const fromAUC = (auc: number): IYearFormats => {
    return {
        ad: toAD(auc),
        auc: auc,
        roman_numerals: toRoman(auc),
    }
}

export const fromAD = (ad: number): IYearFormats => {
    return {
        ad: ad,
        auc: toAUC(ad),
        roman_numerals: toRoman(toAUC(ad)),
    }
}

export const fromRoman = (numerals: string): IYearFormats => {
    const auc = toArabic(numerals)

    return {
        ad: toAD(auc),
        auc: auc,
        roman_numerals: numerals,
    }
}
