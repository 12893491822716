import React from "react"
import styled from "styled-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTwitter} from "@fortawesome/free-brands-svg-icons"

const StyledFooter = styled.div`
    margin-top: 10px;
    color: #8b8b8b;
    width: 80%;
    max-width: 680px;
`

const TwitterLink = styled.a`
    color: #526d7a;
    text-decoration: none;
`

const Separator = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #eee;
    box-sizing: content-box;
    height: 0;
    border-color: ##eee;
`
const FooterContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
`

const CopyrightNotice = styled.i`
    margin-right: 6px;
`

export function Footer(): JSX.Element {
    return (
        <StyledFooter>
            <Separator />
            <FooterContent>
                <CopyrightNotice>© AUC 2773</CopyrightNotice>
                <TwitterLink href="https://twitter.com/presentDate" target="_blank">
                    <FontAwesomeIcon icon={faTwitter} />
                </TwitterLink>
            </FooterContent>
        </StyledFooter>
    )
}
