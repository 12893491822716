import React from "react"
import "./App.scss"
import styled from "styled-components"
import {useSelector} from "react-redux"
import {selectCurrent} from "./store/store.selectors"
import {Calculator} from "./components/Calculator"
import {Footer} from "./components/Footer"

const CenterContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding-bottom: 30px;
`

const YearContent = styled.b`
    font-size: 3em;
    @media (max-width: 600px) {
        font-size: 2em;
    }
`
const YearWrapper = styled.b`
    font-size: 3em;
    @media (max-width: 600px) {
        font-size: 2em;
    }
    margin: 0px 30px;
`
const Explanation = styled.div`
    max-width: 700px;
    text-align: justify;
    text-align-last: center;
    margin: 0px 30px;
`

export function App(): JSX.Element {
    const {auc, roman_numerals} = useSelector(selectCurrent)

    return (
        <CenterContent className="App">
            <YearWrapper>
                <br />
                We Live In The Year <br />
                <YearContent>{auc}</YearContent> <br />
                From The Founding Of Rome <br />
                <br />
            </YearWrapper>
            <a
                href="https://twitter.com/intent/tweet?button_hashtag=AUC&ref_src=twsrc%5Etfw"
                className="twitter-hashtag-button"
                data-show-count="false"
            >
                Tweet #AUC
            </a>
            <Explanation>
                <p>
                    We live in the year AUC {auc}, or AUC {roman_numerals} in Roman numerals.
                </p>
                <p>
                    Ab Urbe Condita, often abbreviated as AUC, means "From The Founding Of The
                    City". It refers to the year of the founding of Rome, which was the Epoch used
                    in Ancient Rome.
                </p>
            </Explanation>
            <Calculator />
            <Footer />
        </CenterContent>
    )
}
